import {
  ActionDefinition,
  ConnectionBuilder,
} from '../utils/manifest-definitions';
import {Translations} from '../utils/translations';
import {getCurrentVersion} from '../utils/ci-actions';

export class ConnectedComponentModel {
  readonly connection: any;
  readonly role: string;
  readonly labelKey: string;

  constructor(role, connection, labelKey?: string) {
    this.connection = connection;
    this.role = role;
    this.labelKey = labelKey;
  }

  get shouldTranslateLabel() {
    return !!this.labelKey;
  }
}

export interface BaseComponentModelProps {
  nickname: string;
  label: string;
  mainAction1: ActionDefinition;
  mainAction2: ActionDefinition;
  helpId: string;
  connectedComponents?: ConnectedComponentModel[];
  isButtonWidget?: boolean;
}

export class BaseComponentModel {
  readonly nickname: string;
  readonly label: string;
  readonly mainAction1: ActionDefinition;
  readonly mainAction2: ActionDefinition;
  readonly helpId: string;
  readonly isButtonWidget: boolean;
  connectedComponents?: ConnectedComponentModel[];

  constructor({nickname, label, mainAction1, mainAction2, helpId, connectedComponents, isButtonWidget}: BaseComponentModelProps) {
    this.nickname = nickname;
    this.label = label;
    this.mainAction1 = mainAction1;
    this.mainAction2 = mainAction2;
    this.helpId = helpId;
    this.isButtonWidget = isButtonWidget;
    this.connectedComponents = connectedComponents;
  }
}

function replaceHtmlInnerText(html, newText) {
  const end = html.substring(html.indexOf('</'));
  if (end === -1) {
    return newText;
  }
  const begining = html.substring(0, html.substring(0, html.indexOf('</')).lastIndexOf('>') + 1);
  return begining + newText + end;
}

export class BaseEditorComponent {
  readonly baseComponentModel: any;

  constructor(componentModel: BaseComponentModel) {
    this.baseComponentModel = componentModel;
  }

  private async getConnectedComponentRef(editorSDK, token, componentRef, role) {
    const children = await editorSDK.components.getChildren(token, {componentRef});
    const controllerRef = children[0];
    const connectedComponents = await editorSDK.document.controllers.listControllerConnections(token, {controllerRef});
    const component = connectedComponents.find(component => component.connection.role === role);
    if (component) {
      return component.componentRef;
    }
    return null;
  }

  private getTranslatableComponents() {
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      return this.baseComponentModel.connectedComponents.filter(component => component.shouldTranslateLabel);
    }
    return [];
  }

  async translateLabels(editorSDK, token, controllerRef) {
    const siteLanguageCode = await editorSDK.document.info.getRegionalLanguage(token);
    const siteTranslation = new Translations();
    await siteTranslation.init(siteLanguageCode, await getCurrentVersion(editorSDK, token));
    const translatableComponents = this.getTranslatableComponents();
    const updateTranslationPromises = [];
    translatableComponents.forEach((component) => {
      updateTranslationPromises.push((async () => {
        const componentRef = await this.getConnectedComponentRef(editorSDK, token, controllerRef, component.role);
        const currentData = await editorSDK.components.data.get(token, {componentRef});
        await editorSDK.components.data.update(token, {
          componentRef, data: {
            text: replaceHtmlInnerText(currentData.text, siteTranslation.t(component.labelKey)),
          }
        });
      })());
    });
    return Promise.all(updateTranslationPromises);
  }

  get connection() {
    let baseConnectionBuilder = new ConnectionBuilder()
      .withNickname(this.baseComponentModel.nickname)
      .withLabel(this.baseComponentModel.label)
      .withMainAction1(this.baseComponentModel.mainAction1)
      .withMainAction2(this.baseComponentModel.mainAction2)
      .withHelpId(this.baseComponentModel.helpId)

    let connections = {};
    if(this.baseComponentModel.connectedComponents?.length > 0) {
      this.baseComponentModel.connectedComponents.forEach(component => {
        connections[component.role] = component.connection;
      });
      baseConnectionBuilder.withConnections(connections);
    }
    if (this.baseComponentModel.isButtonWidget) {
      baseConnectionBuilder
        .withoutFirstTimeModal()
        .withDisabledLinkAbility()
        .withDisabledRotatability()
    }
    return baseConnectionBuilder.build();
  }
}
