import {Translations} from './translations';

export const manageBookingsEvent = 'manageBookings';
export const openBookButtonSettingsEvent = 'openBookButtonSettings';
export const manageStateEvent = 'manageState';

export function generateActions(translation: Translations) {
  return {
    manageBookingsAction : {
      label: translation.t('bookings-pages.actions.manage'),
      actionId: manageBookingsEvent
    },
    manageStateAction : {
      label: translation.t('bookings.daily-timetable.component.label.SwitchState'),
      actionId: manageStateEvent
    },
    bookButtonSettings : {
      label: translation.t('bookings.book-button.component.gfpp.Settings'),
      actionId: openBookButtonSettingsEvent
    },
  }
}
