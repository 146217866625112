import {
  ConnectionBuilderFactory,
} from '../utils/manifest-definitions';
import {BaseComponentModel, BaseEditorComponent, ConnectedComponentModel} from './base-editor-component';

export enum SlotRepeaterState {
  LOADING = 'loaderState',
  LIST = 'slotRepeaterState',
  NO_CLASSES = 'noClassesState'
}

export enum DailyTimetableComponentRoles {
  DATE_RANGE_LABEL = 'dateRangeLabel',
  TIMETABLE_TITLE = 'timetableTitle',
  LEFT_BUTTON = 'leftButton',
  RIGHT_BUTTON = 'rightButton',
  SLOT_REPEATER = 'slotRepeater',
  SLOT_CONTAINER = 'slotContainer',
  DAYS_TOOLBAR = 'daysToolbar',
  DAY_ITEM = 'dayItem',
  DAY = 'day',
  DAY_PICKER_BUTTON = 'dayPickerButton',
  STATE_BOX = 'statebox',
  LOADER_IMAGE = 'loaderImage',
  TIME = 'time',
  DURATION = 'duration',
  SERVICE_NAME = 'serviceName',
  STAFF_MEMBER = 'staffMember',
  SPOTS = 'spots',
  SERVICES_DIVIDER = 'servicesDivider',
  MESSAGE_TEXT = 'messageText',
  MESSAGE_BOX = 'messageBox',
  NO_SERVICES_DIVIDER = 'noServicesDivider',
}

export class DailyTimetableEditorComponent extends BaseEditorComponent {
  constructor(editorTranslation, manageBookingsAction, manageStateAction) {
    const connectionFactory = new ConnectionBuilderFactory(editorTranslation, manageBookingsAction);
    const connectedComponents = [
      new ConnectedComponentModel(DailyTimetableComponentRoles.TIMETABLE_TITLE, connectionFactory.editableText(editorTranslation.t('bookings.daily-timetable.component.label.TimetableHeading')), 'bookings.daily-timetable.component.content.TimetableHeading'),
      new ConnectedComponentModel(DailyTimetableComponentRoles.MESSAGE_TEXT, connectionFactory.editableText(editorTranslation.t('bookings.daily-timetable.component.label.EmptyState'), '2af923bc-c554-4877-b9c7-569f66041543'), 'bookings.daily-timetable.component.content.EmptyState'),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DATE_RANGE_LABEL, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.DateDisplay'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.LEFT_BUTTON, connectionFactory.link(editorTranslation.t('bookings.daily-timetable.component.label.ArrowButton'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.RIGHT_BUTTON, connectionFactory.link(editorTranslation.t('bookings.daily-timetable.component.label.ArrowButton'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.SLOT_REPEATER, connectionFactory.repeater(editorTranslation.t('bookings.daily-timetable.component.label.ClassesAvailable'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.SLOT_CONTAINER, connectionFactory.repeaterItem(editorTranslation.t('bookings.daily-timetable.component.label.ClassDetails'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DAYS_TOOLBAR, connectionFactory.repeater(editorTranslation.t('bookings.daily-timetable.component.label.WeekDisplay'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DAY_ITEM, connectionFactory.repeaterItem(editorTranslation.t('bookings.daily-timetable.component.label.DayContainer'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DAY, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.DayText'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DAY_PICKER_BUTTON, connectionFactory.linkDisabledText(editorTranslation.t('bookings.daily-timetable.component.label.DateButton'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.STATE_BOX, connectionFactory.stateBox(editorTranslation.t('bookings.daily-timetable.component.label.StateBox'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.LOADER_IMAGE, connectionFactory.loaderImage(editorTranslation.t('bookings.daily-timetable.component.label.ClassesLoading'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.TIME, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.Time'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.DURATION, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.Duration'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.SERVICE_NAME, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.ServiceName'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.STAFF_MEMBER, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.StaffText'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.SPOTS, connectionFactory.uneditableText(editorTranslation.t('bookings.daily-timetable.component.label.Availability'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.SERVICES_DIVIDER, connectionFactory.divider(editorTranslation.t('bookings.daily-timetable.component.label.Separator'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.MESSAGE_BOX, connectionFactory.textBox(editorTranslation.t('bookings.daily-timetable.component.label.EmptyStateContainer'))),
      new ConnectedComponentModel(DailyTimetableComponentRoles.NO_SERVICES_DIVIDER, connectionFactory.divider(editorTranslation.t('bookings.daily-timetable.component.label.Separator'))),
      new ConnectedComponentModel(SlotRepeaterState.LIST, connectionFactory.stateBoxState(editorTranslation.t('bookings.daily-timetable.component.label.ClassesAvailable'))),
      new ConnectedComponentModel(SlotRepeaterState.LOADING, connectionFactory.stateBoxState(editorTranslation.t('bookings.daily-timetable.component.label.ClassesLoading'))),
      new ConnectedComponentModel(SlotRepeaterState.NO_CLASSES, connectionFactory.stateBoxState(editorTranslation.t('bookings.daily-timetable.component.label.NoClasses'))),
    ];
    const componentModel = new BaseComponentModel({
      nickname: 'timetableWidget',
      label: editorTranslation.t('bookings.daily-timetable.component.label.Timetable'),
      mainAction1: manageBookingsAction,
      mainAction2: manageStateAction,
      helpId: '0b516ee7-36ca-48c8-9a1d-95ce926802ef',
      connectedComponents,
    });
    super(componentModel);
  }
}
